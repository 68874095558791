@media (max-width: 1600px) {
  .feature_area {
    overflow: hidden;
  }
}
@media (max-width: 1500px) {
  .download_img img {
    max-width: 100%;
  }
}
@media (max-width: 1250px) {
  /*.f-image {*/
  /*  width: calc(100% - 580px);*/
  /*}*/
  .testimonial-area:before {
    left: 0%;
  }
  .hero-title {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .feature_area .feature_inner .feature_list img {
    max-width: 100%;
    right: 0px;
  }
  .download_app_area .download_app_inner .download_img img {
    max-width: 100%;
  }
  .testimonial-left {
    float: none;
  }
  .testimonials_slider {
    margin: 0px 0px;
  }
  .hero-ripples {
    height: 700px;
  }
  .hero-area2 {
    min-height: 1070px;
  }
  .how-work-area {
    padding: 230px 0px 90px;
  }
  .how-work2 {
    padding-top: 114px;
    padding-bottom: 0px;
  }
  .how-w3 {
    padding-top: 90px;
  }
  .features-area {
    padding-top: 144px;
    padding-bottom: 100px;
  }
  .f-content {
    padding: 80px 75px 85px 20px;
  }
  .features-item.f-item2 .f-content {
    padding-right: 20px;
    padding-left: 100px;
  }
  .tab-features li + li {
    padding-left: 50px;
  }
  .testimonial-left {
    width: 100%;
    padding: 0px 15px;
    margin-bottom: 40px;
  }
  .testimonial-area {
    padding: 87px 0px 100px;
  }
  .testimonial-left .sec-title {
    max-width: 100%;
    text-align: center;
    margin-top: 0px;
  }
  .testimonial-left .sec-title h2 {
    margin-bottom: 15px;
  }
  .testimonial-left .sec-title h2:before {
    left: 50%;
    transform: translateX(-50%);
  }
  .testimonial-right {
    width: 100%;
    float: none;
  }
  .swiper-slide {
    width: calc(100% / 3);
    padding: 50px 30px 59px;
  }
  .testimonial-area:before {
    display: none;
  }
  .footer-bottom .b-lists li + li {
    padding-left: 20px;
  }
  .footer-bottom p {
    font-size: 15px;
  }
}
@media (max-width: 1050px) {
  /*.f-image {*/
  /*  width: calc(100% - 530px);*/
  /*}*/
}

@media (max-width: 991px) {
  .header-area .menu li a.nav-link {
    font: 400 16px/40px "Poppins", sans-serif;
  }
  .header-area .menu li + li {
    padding-left: 0px;
  }
  .header-area .navbar-brand {
    padding-left: 15px;
  }
  .header-area .menu {
    padding: 10px 20px;
  }
  .header-area .menu li a::before {
    bottom: 9px;
  }
  .navbar-light .navbar-toggler {
    cursor: pointer;
    position: relative;
    height: 20px;
    width: 30px;
    top: 0px;
    border: none;
    padding: 0px;
    margin-top: 26px;
    margin-bottom: 26px;
    right: 15px;
  }
  .navbar-light .navbar-toggler span {
    height: 2px;
    width: 30px;
    display: block;
    background: #fff;
    cursor: pointer;
    transition: background 0.3s linear;
    position: absolute;
    left: 0px;
    top: 50%;
  }
  .navbar-light .navbar-toggler[aria-expanded="true"] span {
    background: transparent;
  }
  .navbar-light .navbar-toggler.collapsed span {
    background: #fff;
  }
  .navbar-light .navbar-toggler span:before {
    content: "";
    height: 2px;
    width: 30px;
    display: block;
    background: #fff;
    left: 0px;
    top: -10px;
    position: absolute;
    transition: top 0.3s 0.3s, -webkit-transform 0.3s 0s;
  }
  .navbar-light .navbar-toggler span:after {
    content: "";
    height: 2px;
    width: 30px;
    display: block;
    background: #fff;
    left: 0px;
    bottom: -10px;
    position: absolute;
    transition: bottom 0.2s 0.3s, -webkit-transform 0.3s 0s;
  }
  .navbar-light .navbar-toggler[aria-expanded="true"] span:before {
    transform: rotate(45deg);
    top: 0px;
    transition: top 0.3s 0s, transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
  }
  .navbar-light .navbar-toggler[aria-expanded="true"] span:after {
    transform: rotate(-45deg);
    bottom: 0px;
    transition: bottom 0.3s 0s, transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
  }

  .header-area {
    padding: 10px 0px 10px 0px;
  }
  .main_menu.scrollled .header-area {
    padding: 0px 0px 0px 0px;
  }
  .header-area.affix {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main_menu .header-area .navbar-collapse {
    background: linear-gradient(-60deg, #92caff 0%, #797fff 100%);
  }
  .hero-area {
    height: 765px;
  }
  .hero-content h1 {
    font-size: 35px;
  }
  .hero-area2 {
    min-height: 920px;
  }
  .hero-area2:after {
    background-size: contain;
  }
  .how-work-area {
    padding: 274px 0px 90px;
  }
  .how-work2 {
    padding: 132px 0px 0px;
  }
  .how-w3 {
    padding-top: 90px;
  }
  .work-item h2 {
    font-size: 22px;
    padding-bottom: 5px;
  }
  .work-item p {
    font-size: 15px;
  }
  .f-image,
  .f-br::before {
    display: none;
  }
  .f-content,
  .features-item.f-item2 .f-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .features-item {
    margin-bottom: 40px;
  }
  .features-area {
    padding-top: 100px;
  }
  .features-item.f-item2 {
    margin-bottom: 0px;
  }
  .powerful-features {
    padding: 100px 0px 98px;
  }
  .powerful-features:before {
    background-image: -moz-linear-gradient(
      0deg,
      rgba(107, 180, 250, 0.8) 50%,
      rgba(163, 167, 252, 0.8) 50%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgba(107, 180, 250, 0.8) 50%,
      rgba(163, 167, 252, 0.8) 50%
    );
    background-image: linear-gradient(
      0deg,
      rgba(107, 180, 250, 0.8) 50%,
      rgba(163, 167, 252, 0.8) 50%
    );
  }
  .powerful-items.power-item-l {
    padding-bottom: 172px;
    padding-right: 15px;
  }
  .powerful-items.power-item-r {
    padding-left: 15px;
  }
  .vs-icon {
    top: -138px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
  .tab-features {
    text-align: center;
  }
  .tab-features li {
    display: inline-block;
    float: none;
  }
  .tab-features li + li {
    padding-left: 10px;
  }
  .swiper-slide {
    width: calc(100% / 2);
  }
  .testimonial-right {
    padding: 0px 15px;
  }
  .pricing-area {
    padding: 88px 0px 100px;
  }
  .purchase-btn {
    padding: 6px 40px;
  }
  .subscribe-area {
    padding: 90px 0px 100px;
  }
  .f-widget {
    min-height: 266px;
    margin-bottom: 30px;
  }
  .footer-top {
    padding: 100px 0px 0px;
  }
  .footer-bottom {
    text-align: center;
    padding: 15px 0px;
  }
  .footer-bottom .b-lists {
    padding-top: 5px;
    text-align: center;
  }
  .tab-features a {
    width: calc(100% / 2);
    text-align: center;
  }
  .main_text_inner .col-md-6 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .main_bg_area .main_text_inner {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .main_bg_area .main_text_inner .main_right {
    text-align: center;
  }
  .main_bg_area {
    background-size: cover !important;
    min-height: 500px;
    background-position: left !important;
  }
  .main_bg_area .main_text_inner .main_left h3 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 25px;
  }
  .main_bg_area .main_text_inner .main_left h3 samp {
    font-size: 32px;
  }
  .main_bg_area .main_text_inner .main_left {
    padding-top: 135px;
    max-width: 437px;
    margin: 0px auto 50px;
    text-align: center;
  }
  .overview_area {
    margin-top: 50px;
    padding-bottom: 0px;
  }
  .overview_inner .overview_item {
    margin-bottom: 30px;
  }
  .overview_inner {
    margin-bottom: -30px;
  }
  .overview_item {
    padding: 46px 40px;
  }
  .feature_area .feature_inner .feature_text {
    margin-top: 50px;
  }
  .feature_area .feature_inner .feature_img img:nth-child(3) {
    left: 0px;
    bottom: 0px;
  }
  .feature_area .feature_inner .feature_img img:nth-child(2) {
    top: 0px;
  }
  .user_review_area .user_review_slider .prev_arrow {
    left: -55px;
  }
  .user_review_area .user_review_slider .next_arrow {
    right: -55px;
  }
  .team_slider_active .col-lg-3 {
    margin-bottom: 30px;
  }
  .team_slider_active {
    margin-bottom: -30px;
  }
  .l_news_item {
    max-width: 635px;
    margin: 0px auto 30px;
  }
  .l_nesw_list {
    max-width: 635px;
    margin: 0px auto 0px;
  }
  .main_menu.menu_two.scrollled .header-area .navbar-collapse {
    background: #fff;
  }
  .main_menu {
    z-index: 35;
  }
  .menu_two.scrollled .navbar-light .navbar-toggler span {
    background: #332c62;
    transition: all 300ms linear;
  }
  .menu_two.scrollled .navbar-light .navbar-toggler span::before,
  .menu_two.scrollled .navbar-light .navbar-toggler span::after {
    background: #332c62;
  }
  .menu_two.scrollled .navbar-light .navbar-toggler[aria-expanded="true"] span {
    background: transparent;
    transition: all 300ms linear;
  }
}
@media (max-width: 767px) {
  .main_bg_area {
    overflow: hidden;
  }
  .navbar-default .navbar-collapse {
    border: 0px;
    box-shadow: none;
  }
  .main_title h2 br {
    display: none;
  }
  .navbar-toggle {
    border: 0px;
    margin-top: 10px;
    margin-right: 0px;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background: transparent;
  }
  .navbar-default .navbar-toggle .icon-bar {
    width: 30px;
    height: 3px;
    background: #fff;
  }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 5px;
  }
  .header-area .menu li a:hover,
  .header-area .menu li a:focus {
    color: #2e2e7f;
  }
  .header-area .menu {
    background-image: -moz-linear-gradient(90deg, #92caff 0%, #797fff 100%);
    background-image: -webkit-linear-gradient(90deg, #92caff 0%, #797fff 100%);
    background-image: -ms-linear-gradient(90deg, #92caff 0%, #797fff 100%);
    padding: 10px 20px;
    opacity: 0.8;
  }
  .header-area .menu li + li {
    padding-left: 0px;
  }
  .hero-area {
    height: 100%;
  }
  .hero-area2 {
    min-height: 100%;
  }
  .hero-area2:after {
    height: 100%;
    background-size: contain;
  }
  .hero-area2 .hero-img {
    top: 35px;
  }
  .hero-ripples {
    height: 600px;
  }
  .hero-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .hero-content p {
    font-size: 20px;
    line-height: 38px;
  }
  .hero-ripples .hero-content {
    max-width: 100%;
    padding: 0px 15px;
  }
  .how-work-area {
    padding: 175px 0px 90px;
  }
  .how-work-area .sec-title {
    margin-bottom: 35px;
  }
  .how-work2 {
    padding: 116px 0px 0px;
  }
  .how-w3 {
    padding-top: 90px;
  }
  .work-item {
    max-width: 400px;
    margin: 30px auto 0px;
  }
  .f-content {
    text-align: center;
  }
  .f-content h2:before {
    left: 50%;
    transform: translateX(-50%);
  }
  .f-content p {
    font-size: 15px;
    line-height: 32px;
  }
  .swiper-slide {
    width: 100%;
  }
  .pricing-area .sec-title {
    margin-bottom: 35px;
  }
  .pricing-area .price {
    max-width: 400px;
    margin: 30px auto 0px;
  }
  .purchase-btn {
    padding: 6px 65px;
  }
}

@media (max-width: 620px) {
  .f-widget {
    width: 100%;
    min-height: 100%;
  }
  .f-widget .widget_title {
    padding-bottom: 26px;
  }
  .footer-top {
    padding: 80px 0px 40px;
  }
  .hero-content .hero-title {
    font-size: 28px;
    line-height: 38px;
    white-space: nowrap;
  }
}

@media (max-width: 575px) {
  .menu_two .navbar-light .navbar-toggler span {
    background: #332c62;
    transition: all 300ms linear;
  }
  .menu_two .navbar-light .navbar-toggler span::before,
  .menu_two .navbar-light .navbar-toggler span::after {
    background: #332c62;
  }
  .menu_two .navbar-light .navbar-toggler[aria-expanded="true"] span {
    background: transparent;
    transition: all 300ms linear;
  }
  .main_bg_area {
    background: none !important;
  }
  .main_title h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .main_title h2 samp {
    font-size: 26px;
    line-height: 36px;
  }
  .p_100 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .feature_area .feature_inner .feature_img img:nth-child(3) {
    display: none;
  }
  .feature_area .feature_inner .feature_img img:nth-child(2) {
    display: none;
  }
  .main_title {
    margin-bottom: 70px;
  }
  .feature_area .feature_inner .feature_list ul li {
    max-width: 50px;
  }
  .feature_area .feature_inner.feature_tow {
    flex-direction: row-reverse !important;
    padding-top: 95px;
  }
  .feature_area .feature_inner .feature_list ul li:nth-child(3) {
    left: 170px;
    top: -20px;
    animation: 1s up_down2 infinite linear alternate;
  }
  .feature_area .feature_inner .feature_list ul li:nth-child(2) {
    left: 120px;
    animation: 1s up_down infinite linear alternate;
  }
  .feature_area .feature_inner .feature_text h4 {
    font-size: 28px;
    line-height: 40px;
  }
  .user_review_area {
    padding: 70px 0px;
    padding-top: 0px;
  }
  .main_bg_area {
    overflow: hidden;
  }
  .team_slider_active .item {
    max-width: 255px;
    margin: auto;
  }
  .download_app_area .download_app_inner .download_text p {
    padding-right: 0px;
  }
  .l_news_item .l_news_text h4 {
    font-size: 22px;
  }
}
@media (max-width: 480px) {
  .search_box_inner h4 {
    font-size: 20px;
  }
  .search_box_inner p {
    font-size: 14px;
    padding-bottom: 40px;
  }
  .main_bg_area .main_text_inner .main_left .btn_apple_d {
    margin-right: 0px;
    display: block;
    margin-bottom: 10px;
  }
  .search_box_inner .input-group {
    display: block;
  }
  .search_box_inner .input-group input {
    margin-bottom: 20px;
  }
  .download_app_area .download_app_inner .download_text h4 {
    font-size: 28px;
  }
  .overview_item {
    padding: 36px 25px;
  }
  .sec-title h2 {
    padding-bottom: 18px;
    margin-bottom: 25px;
  }
  .sec-title p {
    font-size: 18px;
  }
  .hero-img {
    top: 42px;
  }
  .hero-img img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .how-work-area {
    padding: 113px 0px 70px;
  }
  .how-work2 {
    padding: 95px 0px 0px;
  }
  .how-w3 {
    padding-top: 66px;
  }
  .features-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .f-content {
    padding: 42px 15px 45px;
  }
  .f-content p {
    padding: 30px 0px;
  }
  .f-content h2 {
    font-size: 27px;
    line-height: 32px;
  }
  .powerful-features {
    text-align: center;
    padding: 80px 0px 78px;
  }
  .powerful-item {
    margin-top: 35px;
    display: block;
  }
  .powerful-item .media-left {
    display: block;
    margin-bottom: 20px;
    padding-right: 0px;
  }
  .po-title {
    font-size: 24px;
  }
  .features-content .tab-pane .sec-title h2 {
    font-size: 27px;
  }
  .tab-features {
    padding-top: 25px;
  }
  .tab-features li a {
    line-height: 60px;
  }
  .tab-features li {
    display: block;
  }
  .tab-features li + li {
    padding-left: 0px;
  }
  .testimonial-area {
    padding: 67px 0px 80px;
  }
  .swiper-slide {
    padding: 50px 15px 59px;
  }
  .sec-title h2 {
    font-size: 33px;
  }
  .pricing-area {
    padding: 68px 0px 80px;
  }
  .subcribe label {
    position: relative;
    top: 20px;
    display: block;
  }
  .subcribe label .sub_btn {
    width: 100%;
  }
  .subcribe label .sub_btn:before {
    top: -56px;
    height: 266%;
  }
  .subscribe-area .sec-title h2 {
    font-size: 30px;
    line-height: 38px;
  }
  .footer-bottom .b-lists {
    padding-top: 0px;
  }
  .footer-bottom .b-lists li + li {
    padding-left: 5px;
  }
  .footer-bottom p {
    font-size: 14px;
    line-height: 20px;
  }
  .footer-bottom .b-lists li a {
    font-size: 13px;
    line-height: 18px;
  }
  .tab-features a {
    width: calc(100% / 1);
    text-align: center;
    line-height: 50px;
  }
  .testimonials_slider .item .media .media-left {
    padding-right: 0px;
    margin-bottom: 20px;
  }
  .testimonials_slider .item .media {
    display: block;
  }
  .testimonials_slider .slick-slide {
    padding: 0px 0px;
  }
  .testimonials_slider .item {
    padding: 36px 15px 36px;
  }
  .footer_copyright {
    padding: 45px 15px;
  }
  .footer_copyright h4 {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (max-width: 400px) {
  .btn_apple_w {
    display: block;
    max-width: 165px;
    margin: 0px auto 15px;
  }
  .btn_apple_d {
    display: block;
    max-width: 165px;
    margin-left: 0px;
    margin: auto;
  }
  .l_nesw_list .media .media-body h4 {
    font-size: 16px;
    line-height: 24px;
    padding-right: 0px;
  }
  .l_nesw_list .media .d-flex {
    padding-right: 15px;
  }
}
